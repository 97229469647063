@import "bourbon/bourbon";

$text-color: #333;
$dark-color: #000;
$red-color: #9b1818;
$blue-color: #003469;
$green-color: #67bc5c;

body {
  font-size: 16px;
  font-weight: normal;
  font-family: "RobotoRegular", sans-serif;
  line-height: 1.6;
  position: relative;
  min-width: 320px;
  overflow-x: hidden;
  color: $text-color;
  -webkit-font-smoothing: antialised; }

h1 {
  color: #fff;
  font-size: 60px;
  line-height: 56px;
  font-weight: bold;
  font-family: "CleanvertisingLight", sans-serif;
  margin: 0; }

.loader {
  background: none repeat scroll 0 0 #ffffff;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
  .loader_inner {
    background-image: url(../img/preloader.gif);
    background-size: cover;
    background-repeat: no-repeat;
    background-posirion: center center;
    background-color: #fff;
    height: 60px;
    width: 60px;
    margin-top: -30px;
    margin-left: -30px;
    left: 50%;
    top: 50%;
    position: absolute; } }

section,
footer {
  padding: 58px 0 68px;
  &.s-dark {
    h2, h3, p {
      color: #fff; } }
  h2 {
    color: $blue-color;
    font-size: 50px;
    line-height: 56px;
    margin-bottom: 50px;
    font-family: "CleanvertisingLight", sans-serif;
    text-align: center;
    margin-top: 0;
    font-weight: normal; } }

p strong {
  font-family: "RobotoBold", sans-serif;
  font-weight: normal; }

body input:focus:required:invalid,
body textarea:focus:required:invalid {
  color: red;
  box-shadow: $red-color 0 0 0 2px;
  border-color: $red-color; }
body input:required:valid,
body textarea:required:valid {
	color: green; }

.hidden {
	display: none; }


header {
  min-height: 640px;
  background-image: url(../img/top_bg.jpg);
  background-position: top center;
  background-size: cover;
  color: #fff;
  padding-top: 32px;
  padding-bottom: 40px;
  position: relative; }

.logo {
  font-size: 40px;
  font-family: "RobotoBold", sans-serif;
  font-weight: bold; }

.top-phone {
  text-align: right; }


.tab-item {
  display: none; }
.tab-item:first-child {
  display: block; }
.tabs {
  cursor: pointer; }

.top-phone,
.bottom-phone {
  .wrapper .tabs {
    text-transform: uppercase; } }
.top-phone,
.bottom-phone {
  .wrapper .active {
    text-decoration: underline; } }
.top-phone,
.bottom-phone {
  .tab-item {
    font-size: 34px;
    font-family: "RobotoBold", sans-serif; } }


.top-header {
  margin-top: 70px;

  h2 {
    margin: 10px 0 0;
    font-family: "CleanvertisingLight", sans-serif;
    font-size: 36px; } }

.tabs-header {
  margin-top: 50px;
  ul, li {
    list-style-type: none;
    margin: 0;
    padding: 0; }
  li {
    padding-left: 40px;
    position: relative;
    margin-bottom: 12px; }
  i {
    position: absolute;
    left: 0;
    top: 4px;
    line-height: 26px;
    color: #6f6a67; }
  .tabs {
    .tab {
      display: inline-block;
      background: rgba(255, 255, 255, .25);
      padding: 15px 18px;
      text-transform: uppercase;
      float: left;
      font-family: "RobotoBold", sans-serif;
      @include transition(all .25s ease);
      &.active {
        background: rgba(255, 255, 255, .6);
        color: $dark-color; } } }

  .tab-content {
    font-size: 18px;
    background: rgba(255, 255, 255, .6);
    padding: 12px 18px 16px;
    color: $dark-color;
    text-transform: uppercase;
    font-family: "RobotoBold", sans-serif; } }


.button-wrap {
  text-align: right; }

.button {
  border: none;
  cursor: pointer;
  display: inline-block;
  height: 46px;
  line-height: 46px;
  padding: 0 32px;
  background: $dark-color;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  border-radius: 3px;
  text-decoration: none;
  font-size: 16px;
  font-family: "RobotoBold", sans-serif;
  &.btn-red {
    background: $red-color; }
  &.btn-green {
    background: $green-color; }
  &.btn-white {
    background: #fff;
    color: $dark-color; } }

a.button {
  color: #fff;
  text-decoration: none; }

.s-tizers {
  @include linear-gradient(#c6d8ee, #e8eff7);
  color: $blue-color;
  font-family: "CleanvertisingLight", sans-serif;
  text-transform: uppercase;
  padding: 35px 0 0;

  .row {
    > div {
      margin-bottom: 35px; }

    > div:nth-child(1) {
      .tiz-img {
        position: relative;
        top: 6px; }
      i {
        font-family: "RobotoRegular", sans-serif;
        letter-spacing: -12px;
        margin-right: 18px; }

      strong {
        font-size: 25px;
        line-height: 17px; }
      span {
        font-size: 20px;
        line-height: 40px; } }

    > div:nth-child(2) {
      i {
        position: relative;
        bottom: -2px; }
      strong {
        font-size: 25px;
        line-height: 28px; }
      span {
        font-size: 16px;
        line-height: 23px; } }

    > div:nth-child(3) {
      strong {
        line-height: 28px; }
      span {
        font-size: 16px;
        line-height: 23px; } }

    > div:nth-child(4) {
      i {
        line-height: 58px; }

      span {
        font-size: 13px;
        line-height: 25px; }
      strong {
        line-height: 37px; } } }


  span {
    font-size: 14px;
    display: block; }

  strong {
    font-size: 23px;
    font-weight: normal;
    display: block; }

  .tiz-img,
  .tiz-desc {
    display: inline-block;
    vertical-align: bottom; }



  .tiz-img i {
    font-size: 70px;
    line-height: 52px;
    margin-right: 5px; } }


.profi-left {
  color: $red-color;
  i {
    font-size: 100px;
    line-height: 120px; } }

.profi-right {

  h3 {
    display: inline-block;
    margin: 0;
    color: $red-color;
    font-size: 28px; }
  p {
    margin-top: 5px; } }

form {
  background: #f7f7f7;
  padding: 25px 30px;
  border: 5px solid $green-color;
  border-radius: 5px;
  h2 {
    text-transform: uppercase;
    margin-top: 0;
    font-family: "RobotoBold", sans-serif;
    font-size: 22px;
    color: $text-color;
    line-height: 22px;
    margin-bottom: 30px; }
  label {
    display: block;
    color: #666666;
    font-size: 14px;
    margin-bottom: 15px; }
  input,
  select {
    display: block;
    line-height: 40px;
    height: 40px;
    border: 1px solid #d9d9d9;
    box-shadow: 0 0 20px rgba(0, 0, 0, .06) inset;
    width: 100%;
    text-indent: 10px;
    font-size: 20px;
    margin-top: 3px;

    &:focus {
      box-shadow: 0 0 0 2px $green-color;
      border-radius: 3px;
      border-color: $green-color; } }
  .button-wrap {
    text-align: center;
    margin: 30px 0 10px; } }

.s-review {
  background-image: url(../img/middle_bg.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-color: #412521;
  min-height: 500px;
  .review-item {
    color: #fff;
    text-align: center;
    h4 {
      font-family: "Times New Roman", serif;
      font-style: italic;
      font-size: 22px;
      font-weight: normal;
      margin-top: 20px;
      margin-bottom: 20px; }
    p {
      margin: 30px auto 0;
      display: inline-block;
      float: none;
      font-size: 15px; }
    .img-wrap {
      text-align: center;
      img {
        width: 165px;
        height: 165px;
        display: inline-block;
        border-radius: 50%;
        border: 10px solid #585a5b; } } } }
.hr {
  width: 80px;
  height: 1px;
  background: #cea380;
  margin: auto; }

.owl-nav {
  div {
    width: 34px;
    height: 140px;
    background-image: url(../img/s-arrows.png);
    top: 20px;
    position: absolute;
    opacity: .6;
    &:hover {
      opacity: 1; }
    &.owl-next {
      right: 0;
      background-position: top right; } } }

.owl-dots {
  width: 100%;
  margin-top: 20px;
  text-align: center;
  .owl-dot {
    width: 14px;
    height: 14px;
    background: #fff;
    display: inline-block;
    margin: 0 4px;
    border-radius: 50%;
    opacity: .5;
    &.active {
      opacity: .8; } } }

.s-back {
  background-image: url(../img/red_bg.jpg);
  background-position: top center;
  text-align: center;
  padding: 40px 0;
  h3 {
    margin-top: 0;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 26px;
    text-align: center;
    background-color: rgba(255, 255, 255, .13);
    padding: 10px; } }

.s-contacts {
  padding-bottom: 0;
  .wrapper {
    margin-top: 15px;
    font-size: 18px; }
  .contacts-top {
    text-align: center; }
  h2 {
    color: #000000;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
    height: 45px;
    line-height: 45px; }
  .tabs {
    display: inline-block;
    vertical-align: middle;
    height: 45px;
    line-height: 45px;
    margin-left: 20px;
    .tab {
      float: left;
      padding: 0 60px;
      border: 5px solid $red-color;
      color: $red-color;
      line-height: 38px;
      &:first-child {
        border-radius: 3px 0 0 3px; }
      &:last-child {
        border-radius: 0 3px 3px 0; }
      &.active {
        background: $red-color;
        color: #fff; } } } }
.map {
  margin-top: 20px; }

footer {
  text-align: center;
  h2 {
    color: #000;
    margin-bottom: 30px; }
  p {
    font-size: 18px;
    margin-top: 0; } }

.bottom-phone {
  margin: 50px 0 30px;
  color: #9B1818; }

#hidden-form,
#hidden-formback {
  max-width: 420px;
  margin: auto;
  position: relative;
  h2 {
    text-align: center;
    font-weight: normal; } }
